export const awsConfig = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: '',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: '',
  aws_user_pools_web_client_id: '',
  oauth: {},
  aws_user_files_s3_bucket: '',
  aws_user_files_s3_bucket_region: 'eu-central-1',
  aws_appsync_graphqlEndpoint:
    'https://ux6ojnosyvakleofevnrqfimem.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-6nua3rd6uvfh5fhhx33l7wgeui',
};
